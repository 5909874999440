import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
// ...GatsbyImageSharpFluid

const ProjectsPage = ({ data: { allStrapiProject: { nodes: projects } } }) => {
  return <Layout>
    <section className="projects-page">
      <Projects projects={projects} title="All projects" />
    </section>
  </Layout>
}

export const query = graphql`
  query {
    allStrapiProject {
      nodes {
        id
        github
        title
        description
        featured
        url
        slug
        stack {
        id
        title
       }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData 
            }
          }
        }
      }
    }
  }`

export default ProjectsPage
