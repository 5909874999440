import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FaGithubSquare, FaShareSquare } from "react-icons/fa"
import { navigate } from "gatsby"

const Project = ({ id, description, title, github, stack, url, image, index, slug }) => {
  return <article className="project" onClick={() => { navigate(`/projects/${slug}`) }}>
    {image.localFile && <GatsbyImage image={getImage(image.localFile)} alt="Project image" className="project-img"></GatsbyImage>}
    <div className="project-info">
      <span className="project-number">0{index + 1}.</span>
      <h3>{title}</h3>
      <p className="project-description">
        {description}
      </p>
      <div className="project-stack">
        {stack.map(item => {
          return <span key={item.id}>{item.title}</span>
        })}
      </div>
      <div className="project-links">
        {github && github !== " " && <a href={github}><FaGithubSquare className="project-icon" /></a>}
        {url && <a href={url}><FaShareSquare className="project-icon" /></a>}
      </div>
    </div>
  </article>
}

Project.propTypes = {}

export default Project
